
import UserActivityPanel from "@/components/UserActivityPanel.vue";
import PageTabs from "@/components/PageTabs.vue";
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import * as echarts from "echarts/core";
import {
  GridComponent,
  GridComponentOption,
  TooltipComponent,
  TooltipComponentOption,
  LegendComponent,
} from "echarts/components";
import { LineChart, LineSeriesOption, BarChart, BarSeriesOption } from "echarts/charts";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import { UserModule } from "@/store/modules/user";

echarts.use([
  GridComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition,
  TooltipComponent,
  BarChart,
  LegendComponent,
]);

type EChartsOption = echarts.ComposeOption<
GridComponentOption | LineSeriesOption | TooltipComponentOption | BarSeriesOption
>;

interface IRewardsItem {
  validator: string;
  validatorName: string;
  data: {
    date: string;
    rewards: number;
  }[];
}

interface IApyItem {
  validator: string;
  validatorName: string;
  data: {
    date: string;
    apy: number;
  }[];
}

interface ITotalRewardsItem {
  account: string;
  rewards: number;
  validator: string;
  validatorName: string;
}

interface IActivityResult {
  currentPage: number;
  pageSize: number;
  totalCount: number;
  dataList: {
    id: number;
    account: string;
    type: number;
    validator: string;
    amount: number;
    operationTime: number;
    validatorName: string;
  }[];
}

@Component({
  components: { PageTabs, UserActivityPanel },
  apollo: {
    allValidatorRewardsDays: {
      query: require("../../apollo/graphql/RewardsDays.graphql"),
      variables() {
        return {
          account: UserModule.address,
        };
      },
    },
    allValidatorsApy: {
      query: require("../../apollo/graphql/ValidatorsAPY.graphql"),
      variables() {
        return {
          account: UserModule.address,
        };
      },
    },
    allValidatorRewards: {
      query: require("../../apollo/graphql/RewardsTotal.graphql"),
      variables() {
        return {
          account: UserModule.address,
        };
      },
    },
    pageActivity: {
      query: require("../../apollo/graphql/Activity.graphql"),
      variables() {
        return {
          account: UserModule.address,
          type: 2,
          page: this.currentPage,
        };
      },
    },
  },
})
export default class StakingRewardsPage extends Vue {
  public tabs = ["Amount", "Apy"];

  private tabIndex = 0;

  private currentPage = 1;

  public allValidatorRewardsDays: IRewardsItem[] = [];

  public allValidatorsApy: IApyItem[] = [];

  public allValidatorRewards: ITotalRewardsItem[] = [];

  public pageActivity: IActivityResult = {
    currentPage: 0,
    pageSize: 0,
    totalCount: 0,
    dataList: [],
  };

  public history = [
    {
      name: "Validator1",
      action: "Active",
      amount: 100,
      date: "2020/20/20",
    },
    {
      name: "Validator1",
      action: "Active",
      amount: 100,
      date: "2020/20/20",
    },
    {
      name: "Validator1",
      action: "Active",
      amount: 100,
      date: "2020/20/20",
    },
    {
      name: "Validator1",
      action: "Active",
      amount: 100,
      date: "2020/20/20",
    },
    {
      name: "Validator1",
      action: "Active",
      amount: 100,
      date: "2020/20/20",
    },
  ];

  private topChart?: echarts.ECharts;
  private apyChart?: echarts.ECharts;
  private barChart?: echarts.ECharts;

  private mounted() {
    const chartDom = document.getElementById("chart-content-1")!;
    const chartDom2 = document.getElementById("chart-content-2")!;
    this.topChart = echarts.init(chartDom);
    this.barChart = echarts.init(chartDom2);
  }

  private setupAmountChart() {
    const value = this.allValidatorRewardsDays;
    const dates: string[] = [];
    const series: any = [];
    const legends: string[] = [];
    value.forEach((item, index) => {
      const lineData = {
        name: item.validatorName,
        data: [] as number[],
        type: "line",
        smooth: true,
      };
      const arr: number[] = [];
      item.data.forEach((aData) => {
        if (index === 0) {
          dates.push(aData.date);
        }
        arr.push(aData.rewards);
      });
      lineData.data = arr;
      series.push(lineData);
      legends.push(item.validatorName);
    });
    const option: EChartsOption = {
      xAxis: {
        type: "category",
        data: dates,
      },
      yAxis: {
        type: "value",
        axisLabel: {
          formatter: "{value}",
        },
      },
      legend: {
        data: legends,
      },
      tooltip: {
        trigger: "axis",
      },
      series: series,
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
    };
    this.topChart?.setOption(option);
  }

  private setupAPYChart() {

    if (!this.apyChart) {
      const apyChartDom = document.getElementById('chart-content-1-1')!;
      this.apyChart = echarts.init(apyChartDom);
    }

    const value = this.allValidatorsApy;
    const dates: string[] = [];
    const series: any = [];
    const legends: string[] = [];
    value.forEach((item, index) => {
      const lineData = {
        name: item.validatorName,
        data: [] as number[],
        type: "line",
        smooth: true,
      };
      const arr: number[] = [];
      item.data.forEach((aData) => {
        if (index === 0) {
          dates.push(aData.date);
        }
        arr.push(aData.apy);
      });
      lineData.data = arr;
      series.push(lineData);
      legends.push(item.validatorName);
    });
    const option: EChartsOption = {
      xAxis: {
        type: "category",
        data: dates,
      },
      yAxis: {
        type: "value",
        axisLabel: {
          formatter: "{value} %",
        },
      },
      legend: {
        data: legends,
      },
      tooltip: {
        trigger: "axis",
      },
      series: series,
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
    };
    this.apyChart?.setOption(option);
  }

  private setupBarChart() {
    const names: string[] = [];
    const datas: number[] = [];
    this.allValidatorRewards.forEach((item) => {
      datas.push(item.rewards);
      names.push(item.validatorName);
    });

    const option2: EChartsOption = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: [
        {
          type: "category",
          data: names,
          axisTick: {
            alignWithLabel: true,
          },
        },
      ],
      yAxis: [
        {
          type: "value",
        },
      ],
      series: [
        {
          name: "rewards",
          type: "bar",
          barWidth: "70%",
          data: datas,
        },
      ],
    };

    this.barChart?.setOption(option2);
  }

  private onResize() {
    this.barChart?.resize();
    this.topChart?.resize();
  }

  @Watch("tabIndex")
  tabChange() {
    if (this.tabIndex === 0) {
      this.$nextTick(() => {
        this.setupAmountChart();
      })
    } else {
      this.$nextTick(() => {
        this.setupAPYChart();
      })
    }
  }

  @Watch("allValidatorsApy")
  apyDataChange(value: IApyItem[]) {
    if (value.length > 0 && this.tabIndex === 1) {
      this.setupAPYChart();
    }
  }

  @Watch("allValidatorRewardsDays")
  rewardsChange(value: IRewardsItem[]) {
    if (value.length > 0 && this.tabIndex === 0) {
      this.setupAmountChart();
    }
  }

  @Watch("allValidatorRewards")
  totalRewardsChange(value: ITotalRewardsItem[]) {
    if (value.length > 0) {
      this.setupBarChart();
    }
  }
}
